import React from 'react';
import styled from 'styled-components';
import media from '../../utils/media';
import logo from '../../static/images/Garrett_Rubio_DDS_vector_black.svg';
import {
  Container,
  Col,
  Row,
} from 'reactstrap';
import { Link } from "react-router-dom";

const StyledLink = styled(Link)`
  color: rgb(33, 37, 41);
  font-size: 15px;
  display: block;
  
  ${media.xs `
    display: inline-block;
    margin: 0 1rem;
  `}
  
  ${media.xs `
    margin: 0 1.5rem;
  `}
  
`;

const FooterColumn = styled(Col)`
  text-align: center;
  ${media.xs`text-align: left;`};
`;

const LinkWrapper = styled.div `
  padding-top: 80px;
  text-align: center;
  ${media.xs `
    text-align: right;
  `};
`;

const Footer = () => (
  <footer>
    <div style={{ backgroundColor: 'rgb(215,187,112)', paddingTop: '40px', paddingBottom:'20px' }}>
      <Container>
        <Row>
          <FooterColumn md={4} sm={6}>
            <img src={logo} alt="Garrett Rubio DDS" height="120" width="159"/>
            <br />
            <p style={{ marginTop: "1rem"}}>
              24861 Del Prado
              <br />
              Dana Point, CA 92629
            </p>
          </FooterColumn>
          <FooterColumn md={8} sm={6}>
            <LinkWrapper>
              <StyledLink to="/" style={{ paddingTop: '20px' }}>Home</StyledLink>
              <StyledLink to="/about">About Us</StyledLink>
              <StyledLink to="/services">Our Services</StyledLink>
              <StyledLink to="/patient-info">Patient Info</StyledLink>
              <StyledLink to="/gallery">Photo Gallery</StyledLink>
              <StyledLink to="/contact">Contact Us</StyledLink>
            </LinkWrapper>
          </FooterColumn>
        </Row>
      </Container>
    </div>
    <div style={{ backgroundColor: 'rgb(215,187,112)', textAlign:'center', fontSize: '0.75rem', padding: '10px' }}>
      &#169; 2019 GarrettRubioDDS, Inc. All rights reserved.
    </div>
  </footer>
);

export default Footer;