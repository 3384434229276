import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import styled from 'styled-components';
import thirteen from '../../static/images/bestof/Lantern_Gold_2013.jpg';
import sixteen from '../../static/images/bestof/LANTERN_GOLD_2016.jpg';
import seventeen from '../../static/images/bestof/LANTERN_GOLD_2017.jpg';


const ImageWrap = styled.div `
  text-align: center;
  display: inline-block
`;

const Image = styled.img `
  margin: 2rem;
  max-width: 120px;
`;


export default () => {
  return (
    <Container>
      <Row>
        <Col xs={12}>
          <ImageWrap>
            <Image src={thirteen} alt="Best of Dana Point"/>
            <Image src={sixteen} alt="Best of Dana Point"/>
            <Image src={seventeen} alt="Best of Dana Point"/>
            <h2
                // style={{ display: 'inline-block', fontWeight: '800', maxWidth: '400px', margin: 'auto' }}
            >
              Voted <span>Best Dentist</span> by the Dana Point Times People's Choice Lantern Awards
            </h2>
          </ImageWrap>
        </Col>
      </Row>
    </Container>
  )
}