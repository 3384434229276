import alanaC from '../../static/images/alana_c.jpg'
import brianL from '../../static/images/brian_l.jpg'
import jamieS from '../../static/images/jamie_s.jpg'
import janeH from '../../static/images/jane_h.jpg'
import josefC from '../../static/images/josef_c.jpg'
import kristinaK from '../../static/images/kristina_k.jpg'
import lexL from '../../static/images/lex_l.jpg'
import michelleM from '../../static/images/michelle_m.jpg'
import richardH from '../../static/images/richard_h.jpg'
import richardM from '../../static/images/richard_m.jpg'
import ryanC from '../../static/images/ryan_c.jpg'
import ryanR from '../../static/images/ryan_r.jpg'
import samuelC from '../../static/images/samuel_c.jpg'

export default [
  {
    name: 'Jamie S',
    review: 'Amazing dentists here! Clean, comfortable and affordable. You can really tell they care about their patients. I highly recommend Garrett Rubio to anyone for dental work. Such a nice gentleman as well :)',
    stars: 5,
    image: jamieS,
  },
  {
    name: 'Lex L.',
    review: 'This review is for the best dentist office I\'ve ever had and to each of the individuals who help there. After receiving a prominent referral of Dr. Gerald Frankies from a certain district attorney, and now my whole family and some friends saw Dr. Franke and continue to see Dr. Marco Rubio. Dr. Franke is an unforgettable figure, one of the best and brightest personalities I\'ve ever had the pleasure of knowing. He genuinely wants to help you, the number of times he\'s worked with my family and I through tough times is one of the many reasons we all still don\'t mind but actually enjoy going to the dentist. His wife was one of the nicest people I\'ve ever met, and the two of them made and continue to make the world a better place. The Dr. picked a great replacement so I assume he could live his life to the fullest, and we are all proud of him for it. Dr. Rubio is an equally great doctor, and my family and I will continue to come here for professional, clean, and an overtly friendly and caring staff for dental help. All of them are great people and my family friends and I are grateful for all of them.',
    stars: 5,
    image: lexL,
  },
  {
    name: 'Alana C.',
    review: 'I called for a cleaning appointment and they got me right in. I had a great experience with Maria the hygienist. Dr. Rubio was super informative and friendly. It\'s a beautiful office with all the modern technology.  I will definitely be returning for all my future dental needs. 5 stars all around!',
    stars: 5,
    image: alanaC,
  },
  {
    name: 'Brian L.',
    review: 'I am a former patient of Dr. Rubio\'s and also a former dental school classmate of his and, and I can only say the greatest things of him as a dentist and as a person. He was consistently a top performer in our dental school class with genuine interest in learning about dentistry (winning awards along the way). His patients loved him for the care he put into every exam, restoration, and cleaning he did. He is of excellent moral character as well, and I am 100000% sure that the claims made by fake people on yelp would never happen in his office. He goes beyond the standard of care in sterilizing his instruments and takes great pride in everything in his office. If you\'ve read this far, you would be foolish to go to anyone else for your teeth. If I lived in the area I would definitely be a patient of his. Call his office for an appointment and see for yourself how trustworthy he is!',
    stars: 5,
    image: brianL,
  },
  {
    name: 'Ryan C.',
    review: 'Awesome dental practice! Hygienists are awesome, great with kids, and not too sale-sy! Garrett Rubio is a total babe too!',
    stars: 5,
    image: ryanC,
  },
  {
    name: 'Samuel C.',
    review: 'I have a pretty good dental history but when my wife pointed out that I had some discoloration on one of my front teeth, and when teeth whitening products didn\'t solve the problem, I became concerned. I went to Dr Rubio, he evaluated my condition and quickly diagnosed and presented a treatment plan. I needed a root canal! Obviously I was worried, but Dr Rubio explained very clearly how the process worked and put my mind at ease. Dr Rubio simplifies keeping my teeth healthy and looking great. His staff is extremely professional and makes sure that I am always taken care of.Life can be full of obstacles but, thanks to Dr Rubio and staff, my dental health is not one of them.',
    stars: 5,
    image: samuelC,
  },
  {
    name: 'Richard M.',
    review: 'Awesome service! Dr. Rubio was able to fix a mistake another dentist made and now I\'ve found my new primary dentist! Great staff and great communication as well!',
    stars: 5,
    image: richardM,
  },
  {
    name: 'Michelle M.',
    review: 'Excellent! I visited this office for the first time yesterday and I was very impressed. From the moment I walked in I knew I would like this place. It was a casual environment with some cool music playing. It really eased my anxiety over getting dental work. The hygienist who worked on me was super nice, friendly, and professional. She explained everything she was going to do in detail. I tend to get anxious at the dentist and she was really patient with me. She told me we could take a break from the cleaning if needed. I needed a breather once and she was so cool about it.  She really put me at ease. Dr. Rubio was just as awesome. He took the time to get to know me and explained everything in great detail. You will love this office!',
    stars: 5,
    image: michelleM,
  },
  {
    name: 'Ryan R.',
    review: 'I haven\'t been to the dentist in years and feared going into any office. Dr Rubio and his staff made me feel extremely comfortable and got me going on the work I needed to have my smile healthy again. I would highly recommend this office to anyone looking for the highest quality of care.',
    stars: 5,
    image: ryanR,
  },
  {
    name: 'Richard H.',
    review: 'Dr Rubio and his staff are amazing! Great team, great communication, great experience. From the first phone call to the first time I came in, the whole team was amazing. The office was clean, organized, and had beautiful views. I totally recommend Dr Rubio to anyone who needs a great dentist. You won\'t be disappointed.',
    stars: 5,
    image: richardH,
  },
  {
    name: 'Jane H.',
    review: 'I love Dr. Rubio and his team of amazing staff. Every time I visit I feel right at home and they have done amazing work on my teeth. I would highly recommend!',
    stars: 5,
    image: janeH,
  },
  {
    name: 'Josef C.',
    review: 'Dr. Garrett Rubio is awesome! Great office and very personable staff members. I knew him personally since he was a dental student at UCSF. He is an awesome dentist who goes above and beyond for all of his patients and takes good care of them like his family members! Highly recommend!!!',
    stars: 5,
    image: josefC,
  },
  {
    name: 'Kristina K.',
    review: 'I\'m new to Dana Point and a friend recommended Dr. Rubio for a dentist and I couldn\'t be happier! He made me feel comfortable, his staff is super accommodating and I actually enjoyed the dentist for once. Very honest and trustworthy. Thank you Dr. Rubio!',
    stars: 5,
    image: kristinaK,
  },
]