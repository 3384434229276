import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import services from '../../static/data/services';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Section = styled.section `
  padding-bottom: 80px;
  margin-top: -40px;
`;

const Icon = styled.div `
  background-image: url(${props => props.backgroundImage});
  background-size: cover;
  height: 80px;
  width: 80px;
  margin-bottom: 1rem;
`;

const ListWrap = styled.div `
  box-shadow: 0 0 6px 0px lightgrey;
  border-radius: 6px;
  padding: 1.5rem
  text-align: left;
  margin: 0.5rem 0;
  background-color: #fff;
`;

const Title = styled.h4 `
  font-size: 1.2rem;
  margin-bottom: 1rem;
`;

const ReadMore = styled.h5 `
  font-size: 1.1rem;
  cursor: pointer;
`;

const Underline = styled.span `
  border-bottom: solid 3px rgb(33,37,41);;
  padding-bottom: 4px;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: rgb(33, 37, 41);
    
  &:focus, &:hover, &:visited, &:link, &:active {
    text-decoration: none;
  }
  
  &:hover {
    color: rgb(215, 187, 112);
  }
`;

const List = () => {
  return (
    <Section>
      <Container>
        <Row>
          {
            services.map(service => {
              return (
                <Col lg={3} md={4} sm={6} xs={12}>
                  <ListWrap>
                    <Icon backgroundImage={service.icon} />
                    <Title>{service.title}</Title>
                    <p>{service.description}</p>
                    <ReadMore>
                      <StyledLink to={service.path}><Underline>Find out more</Underline></StyledLink>
                    </ReadMore>
                  </ListWrap>
                </Col>
              )
            })
          }
        </Row>
      </Container>
    </Section>
  )
};

export default List;