import React from 'react';
import { Container, Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Form, TextArea, Text, BasicText, asField } from 'informed';
import styled from 'styled-components';
import axios from 'axios';
import withState from 'recompose/withState';
import compose from 'recompose/compose';
import withHandlers from 'recompose/withHandlers';
import facebookIcon from '../../static/icons/social/facebook.svg'
import googleIcon from '../../static/icons/social/google_maps.svg'
import yelpIcon from '../../static/icons/social/yelp.svg'

const Section = styled.section `
  padding:80px 0;
  background-color: rgb(244, 244, 248);
`;

const buttonStyle = {
  float: "right",
  fontWeight: "800",
  backgroundColor: "rgb(215, 187, 112)",
  boxShadow: "4px 4px 5px",
  padding: "5px 30px",
};

const Icon = styled.div `
  background-image: url(${props => props.image});
  height: 2rem;
  width: 2rem;
  background-size: cover;
  display: inline-block;
  margin: 0.5rem;
`;

const SubHeading = styled.h2 `
  text-align: left;
`;

const handleSubmit = (formState) => {
  axios.post('https://y9o2plo8s9.execute-api.us-west-1.amazonaws.com/dev/generate', formState)
    .then(function (response) {
    })
    .catch(function (error) {
      console.log(error);
    });
};

const closeModal = ({ setModal }) => (formApi) => {
  setModal(false);
  formApi.reset();
};

const ErrorText = asField(({ fieldState, ...props }) => {
  return (
    <React.Fragment>
      <BasicText
        fieldState={fieldState}
        {...props}
        style={fieldState.error ? { border: 'solid 1px red' } : null}
      />
      {fieldState.error ? (
        <small style={{ color: 'red' }}>{fieldState.error}</small>
      ) : null}
    </React.Fragment>
  )});

const ContactForm = (props) => {

  const validatePhone = value => {
    return value && !/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(value)
      ? 'must use a valid US phone number'
      : undefined
  };

  const validateEmail = value => {
    return value && !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value)
      ? 'must use a valid email address'
      : undefined
  };

  return (
    <Section>
      <Form id="contact-us" onSubmit={handleSubmit}>
        {({ formApi }) => (
          <Container>
            <Row>
              <Col md={5} style={{textAlign: "left"}}>
                <SubHeading>GET SOCIAL</SubHeading>
                <div>
                  <a href="https://www.facebook.com/garrettrubiodds/">
                    <Icon image={facebookIcon}/>
                  </a>
                  <a href="https://www.yelp.com/biz/gerald-franke-dds-and-garrett-rubio-dds-dana-point">
                    <Icon image={yelpIcon} />
                  </a>
                  <a href="https://goo.gl/maps/BLPSFgZ72gK2">
                    <Icon image={googleIcon} />
                  </a>
                  <p>
                    <a href="tel:+19496616255">(949) 661-6255</a>
                    <br />
                    <a href="mailto:info@garrettrubiodds.com">info@garrettrubiodds.com</a>
                  </p>
                </div>
                <SubHeading style={{ marginTop: "20px" }}>HOURS OF OPERATION</SubHeading>
                <p>
                  <div>Mon  8:00 am - 5:00 pm</div>
                  <div>Tues 8:00 am - 5:00 pm</div>
                  <div>Wed  8:00 am - 5:00 pm</div>
                  <div>Thu  8:00 am - 5:00 pm</div>
                  <div>Fri Closed</div>
                  <div>Sat  8:00 am - 2:00 pm</div>
                  <div>
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <span style={{fontWeight:'200'}}>(Once a month by appointment)</span>
                  </div>
                  <div>Sun Closed</div>
                </p>
              </Col>
              <Col md={7}>
                <SubHeading style={{paddingBottom: "20px"}}>
                  <span style={{color: "rgb(215, 187, 112)"}}>GET IN TOUCH WITH US </span>
                  <span style={{color: "#000"}}>TODAY!</span>
                </SubHeading>
                <Text field="name" id="name" placeholder="Name" />
                <ErrorText field="email" id="email" placeholder="Email" validate={validateEmail} validateOnBlur />
                <ErrorText field="phone" id="phone" placeholder="Phone" validate={validatePhone} validateOnBlur  />
                <TextArea field="message" id="message" placeholder="Message" />
                <button className="btn btn--primary" type="submit" style={buttonStyle} onClick={() => props.setModal(true)}>SUBMIT</button>
                <Modal isOpen={props.modal} toggle={() => props.closeModal(formApi)}>
                  <ModalHeader toggle={() => props.closeModal(formApi)}>Thanks For Your Message</ModalHeader>
                  <ModalBody>
                    A member of our staff will be in touch with you shortly.
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      style={{ backgroundColor: 'rgb(215, 187, 112)', border: 'none', color: 'black', fontWeight: 'bold' }}
                      onClick={() => props.closeModal(formApi)}
                    >
                      Close
                    </Button>
                  </ModalFooter>
                </Modal>
              </Col>
            </Row>
          </Container>
        )}
      </Form>
    </Section>
  )
};

export default compose(
  withState('modal', 'setModal', false),
  withHandlers({ closeModal }),
)(ContactForm)