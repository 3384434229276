import React, { Component } from "react";
import { Container, Row, Col } from 'reactstrap';
import Slider from "react-slick";
import styled from 'styled-components';
import truncateString from '../../utils/truncateString'
import { ReactComponent as LeftArrow } from '../../static/icons/chevron-circle-left-solid.svg';
import { ReactComponent as RightArrow } from '../../static/icons/chevron-circle-right-solid.svg';
import { ReactComponent as Star } from '../../static/icons/star-solid.svg';
import { ReactComponent as Ribbon } from '../../static/icons/bookmark-solid.svg';
import { ReactComponent as Yelp } from '../../static/icons/yelp-icon.svg';
import media from '../../utils/media';


import reviews from './reviews';

const Section = styled.section `
  height: 800px;
  ${media.lg`
    height: 600px
  `}
  padding: 2rem;
`;

const Wrap = styled.div `
  box-shadow: 0px 0px 8px 1px #ccc;
  margin: 2rem;
  padding: 2rem;
  border-radius: 0.25rem;
  text-align: left;
  position: relative;
  overflow: hidden;
`;

const Reviewer = styled.img `
  border-radius: 100%;
  display: inline-block !important;
`;

const Name = styled.span `
  font-weight: 700;
  padding-left: 0.5rem;
`;

const Review = styled.div `
  padding: 1rem 0;
`;

const StarsWrap = styled.div `
  text-align: center;
`;

const StyledRibbon = styled(Ribbon) `
  position: absolute;
  top: -2px;
  left: 230px;
`;

class SimpleSlider extends Component {
  render() {
    const testimonials = reviews.map(review => {
      return (
        <div key={review.name}>
          <Wrap>
            <StyledRibbon />
            <Reviewer src={review.image} />
            <Name>
              {review.name}
            </Name>
            <br />
            <Review>
              {truncateString(review.review, 350)}
            </Review>
              <StarsWrap>
                <Star />
                <Star />
                <Star />
                <Star />
                <Star />
              </StarsWrap>
          </Wrap>
        </div>
      )
    });
    const settings = {
      infinite: true,
      speed: 5000,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      prevArrow: <LeftArrow to="prev" style={{color: '#212529'}} />,
      nextArrow: <RightArrow to="next" style={{color: '#212529'}} />,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          }
        },
      ]
    };

    return (
      <Section id="testimonials">
        <Container>
          <Row>
            <Col xs={12} >
              <h2>What do our clients say about us?</h2>
              <Slider {...settings}>
                { testimonials }
              </Slider>
            </Col>
          </Row>
        </Container>
      </Section>
    );
  }
}

export default SimpleSlider;