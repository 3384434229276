import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components'
import office from '../../static/images/services_couple.jpg';
import ladies from '../../static/images/ladies_best_of.png';

const StyledLink = styled(Link) `
  color: white !important;
  text-decorator: none;

  &:hover {
    text-decorator: none !important;
  }
`;

const Strive = () => {

  return (
    <section style={{padding: "80px 0", backgroundColor: "rgb(43,49,55)", color: "#fff"}}>
      <Container>
        <Row>
          <Col xs={12}>
            <h2 style={{fontWeight: "800"}}>
              <span style={{color: "rgb(215, 187, 112)"}}>WE STRIVE</span>
              <span> FOR THE BEST</span>
            </h2>
            <p style={{paddingBottom: "20px"}}>
            Our amazing team works hard to insure that you feel at ease during your dental visits, from our first meeting to the formation of lasting friendships, we fully embrace our service to you.
            </p>
          </Col>
        </Row>
      </Container>

      <Container>
        <Row>
          <Col lg={6}>
            <Link to="about">
              <div style={{ width: "90%", height: "350px", margin: "auto", borderTopLeftRadius: "8px", borderTopRightRadius: "8px", backgroundImage: `url(${ladies})`, backgroundSize: "cover", backgroundPosition: "center" }}/>
            </Link>
            <div style={{width:"90%", margin:"auto"}}>

                <div style={{height: "50px", backgroundColor: "rgb(215, 187, 112)", borderBottomLeftRadius: "8px", borderBottomRightRadius: "8px"}}>
                  <h3 style={{top: "50%", position: "relative",transform: "translateY(-50%)",fontWeight: "800", color: "white" }}>
                    <StyledLink to="about">
                    OUR STAFF
                    </StyledLink>

                  </h3>
                </div>
              <div style={{top: "50px",height: "auto",width: "90%",margin: "20px auto"}}>
                Our staff take pride in keeping a relaxing work environment so that every patient has a pleasant experience. The staff are dedicated to keeing the patients safe and healthy.
              </div>
            </div>
          </Col>
          <Col lg={6}>
            <Link to="services">
              <div style={{ width: "90%", height: "350px", margin: "auto", borderTopLeftRadius: "8px", borderTopRightRadius: "8px", backgroundImage: `url(${office})`, backgroundSize: "cover", backgroundPosition: "center" }} />
            </Link>
              <div style={{width:"90%", margin:"auto"}}>
                  <div style={{height: "50px", backgroundColor: "rgb(215, 187, 112)", borderBottomLeftRadius: "8px", borderBottomRightRadius: "8px"}}>
                    <h3 style={{top: "50%", position: "relative",transform: "translateY(-50%)",fontWeight: "800", color: "white"}}>
                      <StyledLink to="services">
                        OUR SERVICES
                      </StyledLink>
                    </h3>
                  </div>
              <div style={{top: "50px",height: "auto",width: "90%",margin: "20px auto"}}>
                We are focused on building a relationship with our clients through education and homecare. We are dedicated to providing the best dental care from preventative dentistry to more specialized treatments.
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
};

export default Strive;