import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import styled from 'styled-components';
import StaffImage from '../../static/images/image1-revised.jpg';

const Section = styled.section `
  padding: 40px 0;
  background-image: url(${StaffImage});
  background-size: cover;
  background-position: top;
`;

const Staff = () => {

  return (
    <Section>
      <Container>
        <Row>
          <Col xs={12} style={{minHeight: "650px"}}>
            <h2>
              <span>OUR </span>
              <span>STAFF</span>
            </h2>
            <p>Our staff take care of their patients through the entire patient journey, from scheduling, cleaning, assisting, and following up with every patient. Our staff take pride in keeping a relaxing work environment so that every patient has a pleasant experience. The staff are dedicated to keeing the patients safe and healthy.</p>
          </Col>
        </Row>
      </Container>
    </Section>
  )
};

export default Staff