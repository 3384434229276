import React from 'react';
import OffsetIntro from '../Components/Intro/OffsetIntro';
import PhotoGallery from '../Components/PhotoGallery/PhotoGallery';
import boardwalk from '../static/images/surfing.jpg'

const Gallery = () => {
  return (
    <div>
      <div>
        <h1>Photo Gallery</h1>
      </div>
      <OffsetIntro
        title={'Photo Gallery'}
        clipped={false}
        height='250px'
      />
      <PhotoGallery  />
    </div>
  );
};

export default Gallery;