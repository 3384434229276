import gallery1 from '../images/gallery/gallery_1.jpg';
import gallery2 from '../images/gallery/gallery_2.jpg';
import gallery3 from '../images/gallery/gallery_3.jpg';
import gallery4 from '../images/gallery/gallery_4.jpg';
import gallery5 from '../images/gallery/gallery_5.jpg';
import gallery6 from '../images/gallery/gallery_6.jpg';
import gallery7 from '../images/gallery/gallery_7.jpg';
import gallery8 from '../images/gallery/gallery_8.jpg';
import gallery9 from '../images/gallery/gallery_9.jpg';
import gallery10 from '../images/gallery/gallery_10.jpg';
import gallery11 from '../images/gallery/gallery_11.jpg';
import gallery12 from '../images/gallery/gallery_12.jpg';
import gallery13 from '../images/gallery/gallery_13.jpg';

export const photos = [
  {
    src: gallery1,
    width: 4,
    height: 3
  },
  {
    src: gallery2,
    width: 4,
    height: 3
  },
  {
    src: gallery3,
    width: 4,
    height: 3
  },
  {
    src: gallery4,
    width: 4,
    height: 3
  },
  {
    src: gallery5,
    width: 3,
    height: 4
  },
  {
    src: gallery6,
    width: 4,
    height: 3
  },
  {
    src: gallery7,
    width: 4,
    height: 3
  },
  {
    src: gallery8,
    width: 4,
    height: 3
  },
  {
    src: gallery9,
    width: 4,
    height: 3
  },
  {
    src: gallery10,
    width: 4,
    height: 3
  },
  {
    src: gallery11,
    width: 4,
    height: 3
  },
  {
    src: gallery12,
    width: 4,
    height: 3
  },
  {
    src: gallery13,
    width: 4,
    height: 3
  },
];
