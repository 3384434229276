import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import styled from 'styled-components';
import aacd from '../../static/images/accreditation/aacd.jpg';
import ada from '../../static/images/accreditation/ada.png';
import OCDS from '../../static/images/accreditation/OCDS.jpg';
import Invisalign from '../../static/images/accreditation/Invisalign.png';
import Cda from '../../static/images/accreditation/cda.png';


const ImageWrap = styled.div `
  text-align: center;
`;

const Image = styled.img `
  margin: 2rem;
  max-width: 150px;
`;


export default () => {
  return (
    <Container>
      <Row>
        <Col xs={12}>
          <ImageWrap>
            <Image src={ada} alt="ADA"/>
            <Image src={aacd} alt="AACD"/>
            <Image src={OCDS} alt="OCDS"/>
            <Image src={Invisalign} alt="Invisalign" />
            <Image src={Cda} alt="CDA" style={{maxWidth: '120px'}}/>
          </ImageWrap>
        </Col>
      </Row>
    </Container>
  )
}