import React from 'react';
import { compose, withState, lifecycle } from 'recompose';
import { Link } from 'react-router-dom'
import styled from 'styled-components';
import './Header.css';
import media from '../../utils/media';
import {
  Container,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  Collapse,
  Nav,
  NavItem,
  NavLink,
  Col,
  Row,
} from 'reactstrap';
import logo from '../../static/images/Garrett_Rubio_DDS.jpg';

const TopbarStyle = styled.div`
  background-color: rgb(215,187,112);
  height: 32px;
  display: none;
  ${media.xs`display: block;`}
`;

const StyledNavbar = styled(Navbar)`
  padding: 20px 10px;
  box-shadow: 0px 2px 10px -2px grey;
  ${media.xs`
    padding: 20px 0px;
  `};
`;

const StyledLink = styled(Link)`
  color: rgb(33, 37, 41);
  font-size: 15px;
`;

const StyledPhoneLink = styled(NavLink)`
  background-color: rgb(33, 37, 41);
  font-size: 15px;
  padding: 6px 12px !important;
  border-radius: 100px;
  max-width: 180px;
  margin: 2px auto;
  color: #fff !important;
`;


const enhance = compose(
  withState('isOpen', 'toggle', false),
  withState('fixed', 'fix', ''),
  withState('topbar', 'hide', ''),
  lifecycle({
    componentDidMount() {
      window.addEventListener('scroll', () => {
        const content = document.getElementById('content');
        if (window.pageYOffset > 30) {
          content.classList.add('fixed');
          this.props.fix('top');
        } else {
          content.classList.remove('fixed');
          this.props.fix('');
        }
      })
    }
  })
);

const TopBar = () => (
  <TopbarStyle>
    <Container>
      <Row>
        <Col sm={6}>
          <span>Request An Appointment </span>
          <span>&#124;</span>
          <span> Working Hours</span>
        </Col>
        <Col sm={6}>Some Text</Col>
      </Row>
    </Container>
  </TopbarStyle>
);

const Logo = styled.div `
  height: 40px;
  width: 108px;
  background: url(${logo});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  ${media.xs`
    height: 60px;
    width 78px;
    background: url(${logo});
    background-size: cover;
  `};
  ${media.sm`
    height: 82px;
    width: 120px;
  `};
`;

const Header = ({ isOpen, toggle, fixed, topbar }) => {
  return (
    <div>
      {/*<TopBar></TopBar>*/}
      {/*<div style={{height:"108px"}}>*/}
        <StyledNavbar fixed="top" color="white" expand="lg">
          <Container>
            <NavbarBrand href="/" style={{paddingRight:'50px',color:'var(--primary-text)'}}>
              <Link to="/">
                <Logo />
              </Link>
            </NavbarBrand>
            <NavbarToggler className="custom-toggler" onClick={() => toggle(!isOpen)} />
            <Collapse isOpen={isOpen} navbar>
              <Nav className="ml-auto" navbar>
                <NavItem>
                  <StyledLink to="/about" className="nav-link">About Us</StyledLink>
                </NavItem>
                <NavItem>
                  <StyledLink to="/services" className="nav-link">Our Services</StyledLink>
                </NavItem>
                <NavItem>
                  <StyledLink to="/patient-info" className="nav-link">Patient Info</StyledLink>
                </NavItem>
                <NavItem>
                  <StyledLink to="/gallery" className="nav-link">Photo Gallery</StyledLink>
                </NavItem>
                <NavItem>
                  <StyledLink to="/contact" className="nav-link">Contact Us</StyledLink>
                </NavItem>
                <NavItem>
                  <StyledPhoneLink href="tel:+19496616255"><i className="fa fa-phone" /> (949) 661-6255</StyledPhoneLink>
                </NavItem>
              </Nav>
            </Collapse>
          </Container>
        </StyledNavbar>
      {/*</div>*/}
  </div>
  )
};


export default enhance(Header);