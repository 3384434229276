import React from 'react';
import { withRouter } from 'react-router-dom';
import find from 'lodash/find';
import services from '../static/data/services';
import OffsetIntro from '../Components/Intro/OffsetIntro';
import Service from '../Components/Service/Service';
import List from '../Components/ServicesList/List';

import boardwalk from '../static/images/dental-chair.jpg'

const ServicePage = ({ location }) => {
  const service = find(services, { path: location.pathname });
  const { title, description, text } = service;
  return (
    <div>
      <OffsetIntro
        title={title}
        clipped={false}
        image={boardwalk}
        height='400px'
      />

      <Service text={text} />
      <List />
    </div>
  );
}

export default withRouter(ServicePage);