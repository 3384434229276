import React from 'react';
import {
  Container,
  Row,
  Col,
} from 'reactstrap';

export default () => (
  <Container fluid>
    <Row>
        <iframe
          width="100%"
          height="550px"
          frameBorder="0"
          style={{border:0}}
          src="https://www.google.com/maps/embed/v1/place?q=24861%20Del%20Prado%2C%20Dana%20Point%20CA%2092629&key=AIzaSyCsFiDMZd-L8ZC44dZJ5KjrHWdRhBNWMv4"
          allowFullScreen
        />
    </Row>
  </Container>
)