import React from 'react';
import OffsetIntro from '../Components/Intro/OffsetIntro';
import Doctor from '../Components/Doctor/Doctor';
import Philosophy from '../Components/Philosophy/Philosophy';
import Staff from '../Components/Staff/Staff';
import StaffImage from '../static/images/ladies_best_of.png';

const title = "ABOUT US";
const description = "Garrett Rubio DDS's mission in to service the regular dental needs of family and community members of South Orange County. Garrett Rubio DDS is a family dental practice that accepts all age groups. It is situated in the heart of Dana Point, California's Lantern District. Dr. Rubio has a unique ability to perform services that go above and beyond most other family dentists from root canals, extractions, and implants.";

export default () => (
  <div>
    <OffsetIntro
      title={title}
      description={description}
      image={StaffImage}
      clipped={false}
    />
    <Doctor />
    <Philosophy />
    <Staff />
  </div>
);