import React from 'react';
import OffsetIntro from '../Components/Intro/OffsetIntro';
import List from '../Components/ServicesList/List';
import dentalChair from '../static/images/dental-chair.jpg'

const title = "OUR SERVICES";
const description = "Garrett Rubio DDS has one goal, to help its patients maintain a healthy smile. Garrett Rubio DDS is a family dental practice. We accept patients of all ages. We want to keep your smile healthy and our patients happy at every single stage in life. We are focused on building a relationship with our clients through education and homecare. We are dedicated to providing the best dental care from preventative dentistry to more specialized treatments.";

export default () => (
  <div>
    <OffsetIntro
      title={title}
      description={description}
      clipped={false}
      image={dentalChair}
    />
    <List />
  </div>
);