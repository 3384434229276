import React from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import { Link } from 'react-router-dom'
import styled from 'styled-components';
import registrationForm from '../../static/forms/dental_registration.pdf'

const Section = styled.section `
  padding: 40px 0;
`;

const RegistrationForm = () => {

  return (
    <Section>
      <Container>
        <Row>
          <Col xs={12}>
            <h2>
              <span>New Patient Registration Form</span>
            </h2>
            <p>Download and complete our new patient registration form prior to your first appointment.</p>
          </Col>
          <div style={{ margin: 'auto '}}>
            <Link to={registrationForm} target="_blank" >
              <Button
                style={{
                  fontWeight: "800",
                  backgroundColor: "rgb(215, 187, 112)",
                  boxShadow: "4px 4px 5px",
                  padding: "5px 30px",
                  marginTop:'15px',
                  border: "none",
                  color: "rgb(33, 37, 41)",
                }}>Download</Button>
            </Link>
          </div>
        </Row>
      </Container>
    </Section>
  )
};

export default RegistrationForm