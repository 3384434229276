import React from 'react';
import Carousel from '../Components/Intro/Carousel';
import Strive from '../Components/Strive/Strive';
import Doctor from '../Components/Doctor/Doctor';
import Contact from '../Components/Contact/Contact'
import Testimonials from '../Components/Testimonials/Testimonials';
import Accreditation from '../Components/Accreditation/Accreditation';
import BestOf from '../Components/BestOf/BestOf';


export default () => (
  <div>
    <Carousel />
    <BestOf />
    <Doctor />
    <Testimonials />
    <Strive />
    <Accreditation />
    <Contact />
  </div>
);