import React from 'react';
import styled from 'styled-components';
import {
  Container,
  Row,
  Col,
} from 'reactstrap'
import media from "../../utils/media";
import dentalChair from '../../static/images/dental-chair.jpg'

const IntroBlock = styled(Container)`
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  text-align: center;
  color: rgb(33, 37, 41);
  padding: 0 20px;
  padding: 15px 20px !important;
  margin-top: 4rem;
  
  ${media.xs`padding 15px 15px: margin-top: auto;`}
`;

const BackgroundImage = styled.div `
  background-image: url(${props => props.image});
  height: ${props => props.height || '600px' }
  width: 100%;
  opacity: 0.5;
  background-size: cover;
  background-position: center;
  
  ${media.sm `
    clip-path: ${props => props.clipped ? 'polygon(60% 0, 100% 0, 100% 100%, 40% 100%);' : 'none;'}
  `}
`;

const OffsetIntro = ({ title, description, image, clipped, height }) => {
  return (
    <Container fluid className="p-0">
      <Row>
        <Col xs="12">
          <BackgroundImage image={image} clipped={clipped} height={height} />
          <IntroBlock>
            <div style={{width:'80%', margin: 'auto'}}>
              <h1 style={{marginBottom:'15px', fontWeight: "800"}}>{title}</h1>
              {description && <p>{description}</p>}
            </div>
          </IntroBlock>
        </Col>
      </Row>
    </Container>
  );
}

export default OffsetIntro;