import React from 'react';
import OffsetIntro from '../Components/Intro/OffsetIntro';
import Service from '../Components/Service/Service';
import RegistrationForm from '../Components/RegistrationForm/RegistrationForm';
import newPatients from '../static/data/new-patients.md';
import boardwalk from '../static/images/boardwalk.jpg';

const PatientInfo = () => {
  return (
    <div>
      <div>
        <h1>New Patient Information</h1>
      </div>
      <OffsetIntro
        title={'New Patient Information'}
        clipped={false}
        image={boardwalk}
        height='400px'
      />
      <Service text={newPatients} />
      <RegistrationForm />
    </div>
  );
};

export default PatientInfo;