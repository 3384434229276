import React from 'react';
import './App.css';
import { BrowserRouter, Route } from 'react-router-dom';
import styled from 'styled-components';
import ScrollToTopRoute from './utils/ScrollToTopRoute';
import Header from './Components/Header/Header';
import Footer from './Components/Footer/Footer';
import Home from './Pages/Home';
import About from './Pages/About';
import Services from './Pages/Services';
import Contact from './Pages/Contact';
import PatientInfo from './Pages/PatientInfo';
import ServicePage from './Pages/Service';
import Gallery from './Pages/Gallery';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

const Wrapper = styled.div `
  text-align: center;
  overflow: hidden;
`;

const App = () =>  (
  <Wrapper className="App">
    <BrowserRouter>
      <div>
        <Header />
        <div id="content">
          <ScrollToTopRoute exact path="/" component={Home} />
          <ScrollToTopRoute exact path="/about" component={About} />
          <ScrollToTopRoute exact path="/patient-info" component={PatientInfo} />
          <ScrollToTopRoute exact path="/services" component={Services} />
          <ScrollToTopRoute exact path="/gallery" component={Gallery} />
          <ScrollToTopRoute exact path="/whitening" component={ServicePage} />
          <ScrollToTopRoute exact path="/invisalign" component={ServicePage} />
          <ScrollToTopRoute exact path="/veneers" component={ServicePage} />
          <ScrollToTopRoute exact path="/implants" component={ServicePage} />
          <ScrollToTopRoute exact path="/extractions" component={ServicePage} />
          <ScrollToTopRoute exact path="/root-canal-therapy" component={ServicePage} />
          <ScrollToTopRoute exact path="/sedation-dentistry" component={ServicePage} />
          <ScrollToTopRoute exact path="/fillings" component={ServicePage} />
          <ScrollToTopRoute exact path="/crowns-and-bridges" component={ServicePage} />
          <ScrollToTopRoute exact path="/periodontics" component={ServicePage} />
          <ScrollToTopRoute exact path="/dental-technology" component={ServicePage} />
          <ScrollToTopRoute exact path="/cleanings" component={ServicePage} />
          <ScrollToTopRoute exact path="/contact" component={Contact} />
        </div>
        <Footer />
      </div>
    </BrowserRouter>
  </Wrapper>
);

export default App;
