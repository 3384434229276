import React, { Component } from "react";
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import styled from 'styled-components';
import {
  Container,
  Row,
  Col,
  Button,
} from 'reactstrap'
import media from '../../utils/media';
import cityArch from '../../static/images/city_arch.jpg';
import officeStaff from '../../static/images/image1-revised.jpg';

const IntroBlock = styled(Container)`
  position: absolute;
  top: 60%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  text-align: left;
  color: #fff;
  padding: 0 20px;
  padding: 15px 20px !important;
  margin-top: 15px;
  
  ${media.xs`padding 15px 15px: margin-top: auto;`}
`;

const IntroImage = styled.div `
  background-image: url(${props => props.backgroundImage});
  background-position: center top;
  height: 700px;
  width: 100%;
  background-size: cover;
  ${media.md`
    background-position-y: -155px
  `}
`;

const Overlay = styled.div `
  height: 100%
  width: 100%;
  background: rgb(0,0,0,0.3);;
  position: absolute;
  height: 700px;
  top: 0;
  ${media.md`
    background: rgb(0,0,0,0.3);
  `}
`;

const Section = styled.section `
  height: 700px
`;

export default class SimpleSlider extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      fade: true,
      autoplay: true,
      autoplaySpeed: 8000,
    };
    return (
      <Section id="intro-carousel">
        <Container fluid className="p-0" style={{minHeight: "450px"}}>
          <Row>
            <Col xs="12">
              <Slider {...settings}>
                <div>
                  <IntroImage backgroundImage={cityArch} />
                </div>
                <div>
                  <IntroImage backgroundImage={officeStaff} />
                </div>
              </Slider>
              <Overlay />
              <IntroBlock>
                <div style={{maxWidth:'600px'}}>
                  <h1 style={{marginBottom:'15px', fontWeight: "800"}}>Serving Dana Point for over 30 years</h1>
                  <h5>Situated conveniently in the Lantern District, the office of Garrett Rubio, DDS welcomes local residents to experience a continued tradition of providing excellent patient-centered care with integrity.</h5>
                  <Link
                    to="/contact"
                  >
                    <Button
                      style={{
                        fontWeight: "800",
                        backgroundColor: "rgb(215, 187, 112)",
                        boxShadow: "4px 4px 5px",
                        padding: "5px 30px",
                        marginTop:'30px',
                        border: "none",
                        color: "rgb(33, 37, 41)",
                      }}>Get Started</Button>
                  </Link>
                </div>
              </IntroBlock>
            </Col>
          </Row>
        </Container>
      </Section>
    );
  }
}