import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import styled from 'styled-components';
import Markdown from 'react-markdown';
import compose from 'recompose/compose';
import lifecycle from 'recompose/lifecycle';
import withState from 'recompose/withState';
import { withRouter } from 'react-router-dom';
import media from '../../utils/media';

const Section = styled.section `
  padding: 80px 0;
  
  &:nth-child(even) {
    background-color: rgb(244,244,248);
  }
`;

const Image = styled.img `
  width: 90%;
  margin: auto;
  display: block;
  float: none;
  margin-bottom: 2rem;
  border-radius: 6px;
  box-shadow: 0 10px 20px 0px rgba(0,0,0,.25);
  
  ${media.sm`
    float: right;
    height: auto;
    width: 400px;
    margin: 1rem;
  `}
`;

const enhance = compose(
  withRouter,
  withState('markdown', 'setMarkdown', ''),
  lifecycle({
    componentWillMount() {
      fetch(this.props.text)
        .then(res => {
          return res.text()
        })
        .then(text => this.props.setMarkdown(text))
    }
  }),
);

const Service = ({ markdown, location }) => {
  return (
    <Section>
      <Container>
        <Row>
          <Col xs={12} style={{textAlign: "left"}}>
            {location.pathname !== '/patient-info' &&
              <Image src={require(`../../static/images/services${location.pathname}.jpg`)} />
            }
            <Markdown source={markdown} />
          </Col>
        </Row>
      </Container>
    </Section>
  )
};

export default enhance(Service);