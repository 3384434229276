import React from 'react';
import { Container, Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Form, TextArea, Text, BasicText, asField } from 'informed';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import axios from 'axios';
import backgroundImg from '../../static/images/surfing.jpg';
import withState from 'recompose/withState';
import compose from 'recompose/compose';
import withHandlers from 'recompose/withHandlers';

const Section = styled.section `
  padding:80px 0;
  background-image: url(${backgroundImg});
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
`;

const buttonStyle = {
  float: "right",
  fontWeight: "800",
  backgroundColor: "rgb(215, 187, 112)",
  boxShadow: "4px 4px 5px",
  padding: "5px 30px",
};

// const Contact = withRouter(props => <ContactForm {...props}/>);

const handleSubmit = (formState) => {
  axios.post('https://y9o2plo8s9.execute-api.us-west-1.amazonaws.com/dev/generate', formState)
    .then(function (response) {
      console.log(response.data.message);
    })
    .catch(function (error) {
      console.log(error);
    });
};

const closeModal = ({ setModal }) => (formApi) => {
  setModal(false);
  formApi.reset();
}

const ErrorText = asField(({ fieldState, ...props }) => {
  return (
    <React.Fragment>
      <BasicText
        fieldState={fieldState}
        {...props}
        style={fieldState.error ? { border: 'solid 1px red' } : null}
      />
      {fieldState.error ? (
        <small style={{ color: 'red' }}>{fieldState.error}</small>
      ) : null}
    </React.Fragment>
  )});

const ContactForm = (props) => {
  const contactPage = (props.location.pathname === "/contact");

  const validatePhone = value => {
    return value && !/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(value)
      ? 'must use a valid US phone number'
      : undefined
  };

  const validateEmail = value => {
    return value && !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value)
      ? 'must use a valid email address'
      : undefined
  };

  return (
    <Section style={contactPage ? { paddingTop: "10rem" } : {} }>
      <h2 style={{paddingBottom: "20px"}}>
        <span style={{color: "rgb(215, 187, 112)"}}>CONTACT US </span>
        <span style={{color: "#fff"}}>TODAY!</span></h2>
      <Form id="contact-us" onSubmit={handleSubmit}>
        {({ formApi }) => (
        <Container>
          <Row>
            <Col md={{size: 3, offset: 2}}>
              <Text field="name" id="name" placeholder="Name" />
              <ErrorText field="email" id="email" placeholder="Email" validate={validateEmail} validateOnBlur />
              <ErrorText field="phone" id="phone" placeholder="Phone" validate={validatePhone} validateOnBlur  />
            </Col>
            <Col md={{size: 5 }}>
              <TextArea field="message" id="message" placeholder="Message" />
              <button className="btn btn--primary" type="submit" style={buttonStyle} onClick={() => props.setModal(true)}>SUBMIT</button>
              <Modal isOpen={props.modal} toggle={() => props.closeModal(formApi)}>
                <ModalHeader toggle={() => props.closeModal(formApi)}>Thanks For Your Message</ModalHeader>
                <ModalBody>
                  A member of our staff will be in touch with you shortly.
                </ModalBody>
                <ModalFooter>
                  <Button
                    style={{ backgroundColor: 'rgb(215, 187, 112)', border: 'none', color: 'black', fontWeight: 'bold' }}
                    onClick={() => props.closeModal(formApi)}
                  >
                    Close
                  </Button>
                </ModalFooter>
              </Modal>
            </Col>
          </Row>
        </Container>
        )}
      </Form>
    </Section>
  )
};

export default compose(
  withRouter,
  withState('modal', 'setModal', false),
  withHandlers({ closeModal }),
)(ContactForm)