import cleanings from '../icons/services/cleanings.svg'
import crowns from '../icons/services/crowns.svg'
import dental_technology from '../icons/services/dental_technology.svg'
import extractions from '../icons/services/extractions.svg'
import fillings from '../icons/services/fillings.svg'
import gums from '../icons/services/gums.svg'
import implant from '../icons/services/implant.svg'
import invisalign from '../icons/services/invisalign.svg'
import root_canal from '../icons/services/root_canal.svg'
import sedation_dentistry from '../icons/services/sedation_dentistry.svg'
import veneer from '../icons/services/veneer.svg'
import whitening from '../icons/services/whitening.svg'
import cleaningsText from './cleanings.md'
import crownsText from './crowns-and-bridges.md'
import dentalText from './dental-technology.md'
import extractionsText from './extractions.md'
import fillingsText from './fillings.md'
import gumsText from './periodontics.md'
import implantsText from './implants.md'
import invisalignText from './invisalign.md'
import rootText from './root-canal-therapy.md'
import sedationText from './sedation-dentistry.md'
import veneerText from './veneers.md'
import whiteningText from './whitening.md'

export default [
  {
    path: "/whitening",
    title: "TEETH WHITENING",
    description: "Teeth whitening is perhaps the easiest and safest cosmetic dental procedure available, and there are many options for achieving the desired results.",
    icon: whitening,
    text: whiteningText,
  },
  {
    path: "/invisalign",
    title: "INVISALIGN",
    description: "Clear aligners provide the ultimate camouflage for teeth alignment. Nearly invisible, Invisalign provides the best aligner system for a modern alternative to wire braces.",
    icon: invisalign,
    text: invisalignText,
  },
  {
    path: "/veneers",
    title: "PORCELAIN VENEERS",
    description: "Veneers can transform a smile dramatically, sometimes referred to as “instant orthodontics.” A set of veneers can correct chips, stains, and even deeply discolored teeth.",
    icon: veneer,
    text: veneerText,
  },
  {
    path: "/implants",
    title: "DENTAL IMPLANTS",
    description: "Dental implants have the ability to fully recreate the structure of a natural tooth, from the appearance of the crown down to the density of the bone.",
    icon: implant,
    text: implantsText,
  },
  {
    path: "/extractions",
    title: "TOOTH EXTRACTION",
    description: "A tooth extraction is generally considered a surgical procedure, and our team is trained to administer this higher level of dental care with great diligence and compassion.",
    icon: extractions,
    text: extractionsText,
  },
  {
    path: "/root-canal-therapy",
    title: "ROOT CANAL THERAPY",
    description: "The procedure, known as root canal therapy, enables us to preserve the outer structure of a tooth while eliminating the pain and infection of a bad nerve.",
    icon: root_canal,
    text: rootText,
  },
  {
    path: "/sedation-dentistry",
    title: "SEDATION DENTISTRY",
    description: "Millions of Americans suffer from dental anxiety, and this fear leads them to avoid necessary dental appointments. Sedation dentistry is the common term used to describe the methods for managing the anxiety and pain associated with dental procedures.",
    icon: sedation_dentistry,
    text: sedationText,
  },
  {
    path: "/fillings",
    title: "FILLINGS",
    description: "Tooth-colored fillings (also called dental composites) repair small cavities and blend in naturally, so you often can’t tell there’s been any dental work.",
    icon: fillings,
    text: fillingsText,
  },
  {
    path: "/crowns-and-bridges",
    title: "CROWNS AND BRIDGES",
    description: "When the overall integrity of the teeth has been compromised, it is often necessary to turn to restorative procedures like dental crowns and bridges. Unlike dental fillings which are used to address small imperfections in the teeth, crowns and bridges provide coverage for larger areas and help to add beauty and stability to your smile.",
    icon: crowns,
    text: crownsText,
  },
  {
    path: "/cleanings",
    title: "CLEANINGS",
    description: "Good strong teeth need a good strong foundation, and that’s why we feel so strongly about protecting your periodontal health. When the bone, gum tissue, and connective fibers become damaged or weakened around your teeth, we may recommend one or more periodontal procedures to control the problem.",
    icon: cleanings,
    text: cleaningsText,
  },
  {
    path: "/periodontics",
    title: "GUM DISEASE AND PERIODONTICS",
    description: "Good strong teeth need a good strong foundation, and that’s why we feel so strongly about protecting your periodontal health. When the bone, gum tissue, and connective fibers become damaged or weakened around your teeth, we may recommend one or more periodontal procedures to control the problem.",
    icon: gums,
    text: gumsText,
  },
  {
    path: "/dental-technology",
    title: "DENTAL TECHNOLOGY",
    description: "We offer modern dental technologies for complete dental care from intra-oral cameras, digital x-rays, panoramic x-rays, and soft-tissue laser.",
    icon: dental_technology,
    text: dentalText,
  }
];