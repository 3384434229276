import React from 'react';
import { Container, Row, Col } from 'reactstrap';

const Philosophy = () => {

  return (
    <section style={{padding:"80px 0"}}>
      <Container>
        <Row>
          <Col xs={12} style={{textAlign: "left"}}>
            <h2>
              <span>OUR </span>
              <span>PHILOSOPHY</span>
            </h2>
            <p>We want you to experience dental care in a comfortable, relaxing environment. Garrett Rubio DDS was chosen to represent this idea because we think you should feel comfortable and at home when you receive your dental care.</p>
            <p>We offer more than your average dental clinic - a warm ambiance throughout the practive that feels inviting and is far from the typical office setting. You'll feel calm and at ease from the moment you step in.</p>
          </Col>
        </Row>
      </Container>
    </section>
  )
};

export default Philosophy