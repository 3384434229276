import React, { useState } from "react";
import compose from "recompose/compose";
import withState from "recompose/withState";
import lifecycle from "recompose/lifecycle";

import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import { photos } from "../../static/data/galleryImages";

const enhance = compose(
  withState('currentImage', 'setCurrentImage', 0),
  withState('viewerIsOpen','setViewerIsOpen', false),
);

const PhotoGallery = ({ setCurrentImage, setViewerIsOpen, viewerIsOpen, currentImage }) => {
  const openLightbox = (event, obj) => {
    setCurrentImage(obj.index);
    setViewerIsOpen(true);
  };
  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  return (
    <div className="container" style={{ paddingBottom: '100px' }}>
      <Gallery photos={photos} onClick={openLightbox} />
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={photos.map(x => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  );
};

export default enhance(PhotoGallery);