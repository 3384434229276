import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import doctorImage from '../../static/images/drG.jpg';
import styled from 'styled-components';
import media from "../../utils/media";

const Headshot = styled.div `
  border-radius: 6px;
  box-shadow: 0 0 10px 1px lightgrey;
  max-width: 200px;
  height: 300px;
  width: 300px;
  background-image: url(${doctorImage});
  background-size: 300px;
  background-position: center;
  margin: auto;
  position: absolute;
  top: 50%;
  left:50%;
  transform: translateY(-50%) translateX(-50%);
  
  ${media.sm`
    margin: unset;
  `}
`;


const Doctor = () => {

  return (
    <section style={{padding:"80px 0", backgroundColor: "rgb(244, 244, 248)"}}>
      <Container>
        <Row>
          <Col md={4} style={{ height: '400px' }}>
            <Headshot />
          </Col>
          <Col md={8}>
            <div style={{textAlign: "left"}}>
              <h2 style={{fontWeight: "800", color: "rgb(215, 187, 112)", margin: 0}}>DOCTOR</h2>
              <h2 style={{fontWeight: "800", margin: 0}}>GARRETT RUBIO, DDS</h2>
              <p style={{marginTop: "10px"}}>
                Dr. Garrett Rubio was born and raised in Mission Viejo and is an alumnus of Mission Viejo High School. He attended college at UCLA, where he obtained his Bachelor’s degree in Physiological Sciences and played football for the UCLA men’s football team. Dr. Rubio went on to obtain his D.D.S. degree from the University of California, San Francisco. He graduated with honors and was inducted into the prestigious Omicron Kappa Upsilon National Dental Honors Society. His passion for dentistry motivated him to further his career by completing a postdoctoral Residency at the Long Beach VA Hospital where he studied the latest in Cosmetic Dentistry, Implantology (dental implants), and Endodontics (root canals). In private practice Dr. Rubio is committed to offering the latest advancements in dentistry, enabling him to provide comfortable, quality care to his patients.
              </p>
              <p>
                Dr. Rubio comes from a long tradition of family dentists. He loves being a part of the Dana Point community and lives locally, where he enjoys an active lifestyle surfing, running the beach trails, and spending time with his family.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
};

export default Doctor